import { useEffect, useState } from 'react'
import EditText from '../widgets/EditText'
import Rooms from './Rooms'
import logout from '../auth/logout'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import CurrentUser from '../queries/CurrentUser'
import UsernameAvailable from '../queries/UsernameAvailable'
import UpdateUser from '../queries/UpdateUser'

export default function Profile() {

    const { data } = useQuery(CurrentUser)
    const originalUsername = data?.user?.username
    const email = data?.user?.email
    const [editing, setEditing] = useState('')
    const [username, setUsername] = useState(originalUsername ?? '')
    const [queryUsername, { data: queryUsernameData, error: queryUsernameError }] = useLazyQuery(UsernameAvailable, {
        fetchPolicy: 'cache-and-network',
        variables: { username }
    })
    const [updateUser, { data: updateUserData, error: updateUserError }] = useMutation(UpdateUser)

    useEffect(() => setUsername(originalUsername ?? ''), [originalUsername])

    function changeFocus(field) {
        setEditing(field)
    }

    let usernameError
    if ((queryUsernameData && !queryUsernameData.usernameAvailable) ||
        (updateUserError && updateUserError.graphQLErrors?.find( error => error.extensions?.code === 'USERNAME_EXISTS' ))) {
        usernameError = 'That user name is not available.'
    }

    async function saveUsername() {
        if (!username || username === originalUsername) return
        try {
            await updateUser({ variables: { updates: { username } } })
        } catch (error) {
            setUsername(originalUsername)
        }
    }

    function usernameChanged(e) {
        const name = e.target.value
        if (name !== username) {
            setUsername(name)
            if (name !== originalUsername)
                queryUsername({ variables: { username: name } })
        }
    }

    return (
        <div className="ProfilePage">
            <div className="ProfileGrid">
                <div className="GridLabel" style={{ gridArea: 'emailLabel' }} onClick={() => changeFocus('')}>Email</div>
                <div className="GridText" style={{ gridArea: 'email' }}>{ email }</div>
                <div className="GridLabel" style={{ gridArea: 'usernameLabel' }} onClick={() => changeFocus('')}>User Name</div>
                <div className="GridText" style={{ gridArea: 'username' }}>
                    <EditText
                        text={ username }
                        onClick={() => changeFocus('username')}
                        onBlur={() => saveUsername()}
                        updateText={usernameChanged}
                        editEnabled={editing === 'username'}
                        error={usernameError}
                    />
                </div>
            </div>
            <div className="ProfileRooms">
                <div className="ProfileRoomsHeader">Rooms</div>
                <Rooms />
            </div>
            <div className="ProfileFooter">
                <button className="Button" onClick={logout}>Logout</button>
            </div>
        </div>
    )
}
