import { Actions } from '../AppReducer'
import { useAppContext, useAppDispatch } from '../AppContext'
import { useMutation } from '@apollo/client'
import AddRequest from '../queries/AddRequest'

export default function useQueueTrack(roomId) {

    const dispatch = useAppDispatch()
    const { playMode } = useAppContext()
    const [ addRequestMutation ] = useMutation(AddRequest)

    async function queueTrack(track, silent) {
        switch (playMode) {
            case 'room': {
                try {
                    await addRequestMutation({ variables: { roomId: roomId, trackId: track.id } })
                    if (!silent)
                        dispatch(Actions.showToast({content: <span>Added <i>{track.title}</i> to the queue.</span>}))
                } catch (error) {
                    console.error(`failed to queue track`, error)
                }
                break
            }
            case 'local': {
                dispatch(Actions.queueTrack(track))
                if (!silent)
                    dispatch(Actions.showToast({content: <span>Added <i>{track.title}</i> to the queue.</span>}))
                break
            }
            default:
                console.error(`unknown play mode: ${playMode}`)
        }
    }

    return queueTrack
}
