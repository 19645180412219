import { useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'
import useQueueTrack from '../hooks/useQueueTrack'

export default function TrackControls(props) {

    const dispatch = useAppDispatch()

    const { track, roomId } = props
    const queueTrack = useQueueTrack(roomId)

    return (
        <div className="TrackControlButtons">
            <span className="material-symbols-outlined TrackControlButton" onClick={(e) => dispatch(Actions.playTrack(track))}>play_circle</span>
            <span className="material-symbols-outlined TrackControlButton" onClick={() => queueTrack(track)}>playlist_add</span>
        </div>
    )
}
