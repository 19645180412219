import { useEffect, useState } from 'react'
import { useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'
import TrackControls from '../track/TrackControls'
import Spinner from '../widgets/Spinner'
import { useQuery } from '@apollo/client'
import Tracks from '../queries/Tracks'
import useCurrentUser from '../hooks/useCurrentUser'
import useCurrentRoom from '../hooks/useCurrentRoom'

export default function Search(props) {

    const dispatch = useAppDispatch()
    const user = useCurrentUser()
    const currentRoom = useCurrentRoom()

    const collections = [{ name: 'My Uploads', value: '' }]
        .concat(user?.rooms?.filter( room => room.admins.find( admin => admin.id === user.id) ).map( room => ({
            name: `Room: ${room.name} - ${room.owner.username}`,
            value: room.id
        })) ?? [])

    const blankParams = { searchType: 'ARTIST', text: '', tags: '', roomId: currentRoom?.id }
    const [params, setParams] = useState(blankParams)

    useEffect(() => { setParams(blankParams) }, [user])

    const {
        loading: tracksLoading,
        error: tracksError,
        data: trackData,
        fetchMore
    } = useQuery(Tracks, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
        variables: { params: { ...params, tags: params.tags.split(/\s+/).filter( x => x) } }
    })
    const trackList = trackData?.tracks?.tracks

    function handleScroll(e) {
        const {scrollTop, scrollHeight, clientHeight} = e.target
        const position = Math.ceil(
            (scrollTop / (scrollHeight - clientHeight)) * 100
        )
        if (!tracksLoading && position > 80 && trackData?.tracks?.cursor) {
            console.log(`fetching more tracks`)
            fetchMore({ variables: { cursor: trackData.tracks.cursor }})
        }
    }

    function paramsUpdated(field, value) {
        console.log(`paramsUpdated => ${field}: ${value}`)
        const newParams = { ...params }
        newParams[field] = value
        setParams(newParams)
    }

    let noResultsContent
    if (!tracksLoading)
        noResultsContent =
            <div className="SearchNoResults">
                <p>No matches for this search.</p>
                <p>To add music you can <span className="Link" onClick={() => dispatch(Actions.navigateTo('upload'))}>upload your music files</span> or purchase new digital music from one of our affiliates.</p>
            </div>

    return (
        <div className="SearchPage">
            <div className="SearchParams">
                <div className="SearchParamsGrid">
                    <div className="GridLabel" style={{ gridArea: 'collectionLabel' }}>Collection</div>
                    <div className="GridText" style={{ gridArea: 'collection' }}>
                        <select value={params.roomId} onChange={(e) => paramsUpdated('roomId', e.target.value)}>
                            { collections.map( collection => <option key={collection.value} value={collection.value}>{ collection.name }</option> )}
                        </select>
                    </div>
                    <div className="GridLabel" style={{ gridArea: 'searchByLabel' }}>Search</div>
                    <div className="GridText" style={{ gridArea: 'searchBy' }}>
                        <select className="SearchTypeSelect" value={params.searchType} onChange={(e) => paramsUpdated('searchType', e.target.value)}>
                            <option value="ARTIST">Artist</option>
                            <option value="TITLE">Title</option>
                            <option value="ALBUM">Album</option>
                        </select>
                        <input className="SearchTextInput" type="text" style={{ width: '200px' }} value={params.text} onChange={(e) => paramsUpdated('text', e.target.value)} />
                    </div>
                    <div className="GridLabel" style={{ gridArea: 'tagsLabel' }}>Tags</div>
                    <div className="GridText" style={{ gridArea: 'tags' }}>
                        <input type="text" style={{ width: '200px' }} value={params.tags} onChange={(e) => paramsUpdated('tags', e.target.value)} />
                    </div>
                </div>
            </div>

            <div className="SearchResults"  onScroll={handleScroll}>
                { trackList?.length > 0 ?
                    <div style={{ display: 'grid', gridTemplateColumns: 'min-content 1fr 1fr 1fr' }}>
                        { trackList.map( (track, index) => [
                            <div className={ index % 2 === 0 ? 'SearchResult Even' : 'SearchResult Odd' } style={{ gridRow: index + 1, gridColumn: 1}} key={`${index}a`}>
                                <TrackControls track={track} />
                            </div>,
                            <div className={ index % 2 === 0 ? 'SearchResult Even' : 'SearchResult Odd' } style={{ gridRow: index + 1, gridColumn: 2 }} key={`${index}b`}>
                                <div>{track.artist}</div>
                            </div>,
                            <div className={ index % 2 === 0 ? 'SearchResult Even' : 'SearchResult Odd' } style={{ gridRow: index + 1, gridColumn: 3 }} key={`${index}c`}>
                                <div className="NavLink" onClick={() => dispatch(Actions.showSheet({ action: 'showTrack', data: track.id }))}>{track.title ?? '[ untitled ]'}</div>
                            </div>,
                            <div className={ index % 2 === 0 ? 'SearchResult Even' : 'SearchResult Odd' } style={{ gridRow: index + 1, gridColumn: 4 }} key={`${index}d`}>
                                <div className="NavLink" onClick={() => dispatch(Actions.showSheet({ action: 'showAlbum', data: track }))}>{track.album}</div>
                            </div>
                        ] ) }
                    </div>
                :
                    noResultsContent
                }
                { tracksLoading ? <div style={{ marginTop: '20px' }}><Spinner /></div> : null }
            </div>
        </div>
    )
}
