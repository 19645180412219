import { useState } from 'react'
import Spinner from '../widgets/Spinner'
import { useMutation } from '@apollo/client'
import CreateUserForEmail from '../queries/CreateAccountWithEmail'

export default function EmailCreateAccountModal(props) {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirm, setConfirm] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [abortController, setAbortController] = useState(new AbortController())
    const [createAccountMutation, { loading }] = useMutation(CreateUserForEmail, { fetchPolicy: 'no-cache' })

    function cancel(e) {
        abortController.abort()
        props.show('')
    }

    function confirmUpdated(e) {
        setErrorMessage('')
        setConfirm(e.target.value)
    }

    async function createAccount(e) {
        const emailProblem = validateEmail()
        if (emailProblem) {
            setErrorMessage(emailProblem)
            return
        }
        const passwordProblem = validatePassword()
        if (passwordProblem) {
            setErrorMessage(passwordProblem)
            return
        }
        const controller = new AbortController()
        setAbortController(controller)
        try {
            await createAccountMutation({
                variables: { email, password },
                context: { fetchOptions: { signal: controller.signal } }
            })
            props.show('verifyEmail')
        } catch (err) {
            if (err.graphQLErrors?.find( gqlErr => gqlErr.extensions?.code === 'USER_EXISTS'))
                setErrorMessage(`There is already an account for that email address.`)
            else if (err.graphQLErrors?.find( gqlErr => gqlErr.extensions?.code === 'UNACCEPTABLE_PASSWORD'))
                setErrorMessage(`The password you provided is not acceptable.  Please try again.`)
            else
                setErrorMessage('There was an error creating your account.  Please try again.')
        }
    }

    function emailUpdated(e) {
        setErrorMessage('')
        setEmail(e.target.value)
    }

    function logIn(e) {
        props.show('emailLogin')
    }

    function passwordUpdated(e) {
        setErrorMessage('')
        setPassword(e.target.value)
    }

    function validateEmail() {
        const validEmailRegex = /^\S+@\S+\.\S+$/
        if (!validEmailRegex.test(email)) return 'Invalid email address.'
        return null
    }

    function validatePassword() {
        if (password.length < 8) return 'Your password must be at least 8 characters long.'
        if (password !== confirm) return 'Your password and the confirmation don\'t match.'
        return null
    }

    return (
        <div className="Dimmer">
            <div className="FullPadding">
            <div className="ContentBox HalfPadding PopUp">
                <div style={{ display: 'grid', gridTemplateColumns: '100px 1fr', gridColumnGap: '5px', gridRowGap: '3px' }}>
                    <div style={{ gridRow: 1, gridColumn: 1, textAlign: 'right' }}>Email:</div>
                    <div style={{ gridRow: 1, gridColumn: 2, textAlign: 'left' }}>
                        <input type="text" style={{ width: '100%' }} value={email} onChange={emailUpdated}/>
                    </div>
                    <div style={{ gridRow: 2, gridColumn: 1, textAlign: 'right' }}>Password:</div>
                    <div style={{ gridRow: 2, gridColumn: 2, textAlign: 'left' }}>
                        <input type="password" style={{ width: '100%' }} value={password} onChange={passwordUpdated}/>
                    </div>
                    <div style={{ gridRow: 3, gridColumn: 1, textAlign: 'right' }}>Confirm:</div>
                    <div style={{ gridRow: 3, gridColumn: 2, textAlign: 'left' }}>
                        <input type="password" style={{ width: '100%' }} value={confirm} onChange={confirmUpdated}/>
                    </div>
                    <div style={{ gridRow: 4, gridColumn: 1, textAlign: 'right' }}>
                        <div style={{ height: '25px', marginTop: '8px', marginRight: '10px', marginBottom: '0px' }}>
                            { loading ? <Spinner /> : null }
                        </div>
                    </div>
                    <div style={{ gridRow: 4, gridColumn: 2, marginTop: '10px', textAlign: 'left' }}>
                        <button style={{ marginRight: '10px' }} onClick={cancel}>Cancel</button>
                        <button style={{ marginRight: '10px' }} disabled={loading} onClick={createAccount}>Create Account</button>
                    </div>
                    <div style={{ gridRow: 5, gridColumn: 2, textAlign: 'right' }}>
                        <p className="Link" style={{ fontSize: '12px' }} onClick={logIn}>Already have an account?</p>
                    </div>
                    { errorMessage.length > 0 ?
                        <div style={{ gridRow: 6, gridColumnStart: 1, gridColumnEnd: 3, textAlign: 'center' }}>
                            <p className="ErrorMessage">{ errorMessage }</p>
                        </div>
                      : null }
                </div>
            </div>
            </div>
        </div>
    )
}